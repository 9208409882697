import React from "react"
import { StyledSelect } from "./Select.styled"

const Select = ({value, options, handleChange}) => {
  const [open, setOpen] = React.useState(false)

  const openSelect = (event) => {
    event.stopPropagation()
    open ? setOpen(false) : setOpen(true)
  }

  const handleClick = (event) => {
    event.stopPropagation()
    setOpen(false)
    handleChange(event.currentTarget.getAttribute('data-value'))
  }

  React.useEffect(() => {
    const closeSelect = () => setOpen(false)
    window.addEventListener('click', closeSelect)
    return () => {
      window.removeEventListener('click', closeSelect)
    }
  })

  return (
    <StyledSelect open={open}>
      <button onClick={openSelect} onDragEnter={openSelect}>
        {options.filter(option => option.value === value)[0].label}
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24">
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
      </button>
      <ul>
        {options.map(
          (option, i) => (<li key={i}>
            <button data-value={option.value} onClick={handleClick} onDragEnter={handleClick}>{option.label}</button>
          </li>)
        )}
      </ul>
    </StyledSelect>
  )
}

export default Select

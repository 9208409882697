import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import TitleSection from "../components/TitleSection"
import ContactSection from "../components/ContactSection"
import Gallery from "../components/Gallery"
import Select from "../components/Select"
import {
  sectionRealizations,
  galleryFilters
} from "../assets/style/pages/realizations.module.scss"

const RealizationsPage = ({ location, data, intl }) => {
  const { title, subtitle } = data.cockpitRealizationsPage
  const featuredImage = data.cockpitRealizationsPage.featuredImage.value.childImageSharp.fluid
  const images = data.allCockpitRealizations.edges.map(({ node }) => ({
    id: node.id,
    image: node.image.value,
    source: node.image.value.url,
    title: node.title.value,
    categories: node.category.value.map(({ slug }) => slug.value)
  }))

  const options = data.allCockpitCategories.edges.map(({ node }) => ({value: node.slug.value, label: node.title.value}))
  options.unshift({ value: 'all', label: intl.formatMessage({ id: "all" })})

  const [category, setCategory] = React.useState(location.state && location.state.category ? location.state.category : 'all')

  const handleChange = (selected) => {
    setCategory(selected)
  }

  return (
    <Layout>
      <Seo lang={intl.locale} title={intl.formatMessage({ id: "realizationspage" })} />
      <TitleSection title={title.value} subtitle={subtitle.value} bg={featuredImage}></TitleSection>
      <section className={sectionRealizations}>
        <div className="container">
          <div className={galleryFilters}>
            <span>{intl.formatMessage({ id: "categories" })}:</span>
            <Select value={category} options={options} handleChange={handleChange} />
          </div>
          <Gallery images={images} category={category} />
        </div>
      </section>
      <ContactSection />
    </Layout>
  )
}

export const query = graphql`
  query($lng: String!) {
    cockpitRealizationsPage(lang: {eq: $lng}) {
      title {
        value
      }
      subtitle {
        value
      }
      featuredImage {
        value {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    allCockpitCategories(filter: {lang: {eq: $lng}}) {
      edges {
        node {
          title {
            value
          }
          slug {
            value
          }
        }
      }
    }

    allCockpitRealizations(filter: {lang: {eq: $lng}}, sort: {fields: cockpitCreated, order: DESC}) {
      edges {
        node {
          id
          image {
            value {
              url
              childImageSharp {
                gatsbyImageData(
                  width: 350,
                  placeholder: BLURRED
                )
              }
            }
          }
          title {
            value
          }
          category {
            value {
              slug {
                value
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(RealizationsPage)

import styled from "@emotion/styled"

export const StyledSelect = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  margin: 10px 0 0 0;

  @media (min-width: 576px) {
    width: 250px;
    margin: 0 0 0 20px;
  }

  button {
    display: block;
    position: relative;
    width: 100%;
    border: none;
    outline: none;
    background: #c9c9c9;
    padding: 5px 40px 5px 20px;
    font-size: .875rem;
    color: #414141;
    text-align: left;
    cursor: pointer;

    svg {
      transform: ${({ open }) => open ? 'rotate(-90deg)' : 'rotate(90deg)'};
      fill: #414141;
      position: absolute;
      top: 50%;
      right: 20px;
      margin: -5px 0 0;
    }
  }

  ul {
    display: ${({ open }) => open ? 'block' : 'none'};
    position: absolute;
    z-index: 999;
    top: 100%;
    left: 0;
    width: 100%;
    margin: 0;
    background: #d8d8d8;
    list-style: none;

    li {
      display: block;
      margin: 0;

      button {
        display: block;
        padding: 5px 20px;
        border: none;
        outline: none;
        background: none;
        font-size: .875rem;
        cursor: pointer;

        &:hover {
          background: #d2d2d2;
        }
      }
    }
  }
`
